import React, { useContext, Fragment } from 'react'
import { Context } from '../context/Context'
import { staticTranslations } from '../context/staticTranslations'

const Translator = props => {
	const { content } = props
	const { french } = useContext(Context)

	if (!staticTranslations[content]) {
		return null
	}

	return (
		<Fragment>
			{french
				? staticTranslations[content].french
				: staticTranslations[content].english}
		</Fragment>
	)
}

export default Translator
