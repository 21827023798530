import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { Provider } from './context/Context'
import { HelmetProvider } from 'react-helmet-async'
import { Top } from './components/Top'
import Header from './components/Header'
import Footer from './components/Footer'
import PageHome from './pages/PageHome'
import PagePrograms from './pages/PagePrograms'
import PageNotFound from './pages/PageNotFound'
import './assets/scss/styles.scss'

const AppRouter = () => {
	return (
		<HelmetProvider>
			<Provider>
				<BrowserRouter>
					<Header />
					<Top />
					<Switch>
						<Route path='/' exact>
							<PageHome />
						</Route>
						<Route path='/programmation'>
							<PagePrograms />
						</Route>
						<Route path='*'>
							<PageNotFound />
						</Route>
					</Switch>
					<Footer />
				</BrowserRouter>
			</Provider>
		</HelmetProvider>
	)
}

export default AppRouter
