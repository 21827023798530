import { useContext, useRef, Fragment } from 'react'
import { Context } from '../context/Context'
import { Helmet } from 'react-helmet-async'
import { staticTranslations } from '../context/staticTranslations'
import Translator from '../components/Translator'
import Loader from '../components/Loader'
import RichText from '../components/RichText'
import HomeMap from '../components/HomeMap'
import Obfuscate from 'react-obfuscate'

const PageHome = () => {
	const { isHomeContentLoading, homeContent, french } = useContext(
		Context
	)
	const { seoHomeTitle, seoHomeDescription } = staticTranslations
	const scrollToRef = ref =>
		window.scrollTo(0, ref.current.offsetTop - 110)

	const myRef = useRef(null)
	const executeScroll = () => scrollToRef(myRef)

	if (isHomeContentLoading) {
		return <Loader />
	}

	return (
		<Fragment>
			<Helmet>
				<title>
					{french
						? seoHomeTitle.french
						: seoHomeTitle.english}
				</title>
				<meta
					name='description'
					content={
						french
							? seoHomeDescription.french
							: seoHomeDescription.english
					}
				/>
			</Helmet>
			<div className='main'>
				<div className='flex-horizontal index marginBottom20'>
					<div className='col col-1 marginBottom50'>
						<HomeMap />
					</div>
					<div className='col col-2 marginBottom50'>
						<div className='asterisk lineright marginBottom30'></div>
						<RichText content={homeContent.adresse} />

						<p className='marginBottom50'>
							<Obfuscate
								className='ob-link'
								tel={homeContent.phone}
							/>
							<br />
							<Obfuscate
								className='ob-link'
								email={homeContent.email}
							/>
						</p>
						<div
							onClick={executeScroll}
							className='arrow'></div>
					</div>
				</div>
				<div className='description index'>
					<h3
						ref={myRef}
						className='lineright marginBottom40'>
						<Translator content='homePageTitle' />
					</h3>
					<div className='flex-vertical'>
						<h3 className='price marginBottom40'>
							{homeContent.prixSemaine}
						</h3>
						<RichText content={homeContent.conditions} />
					</div>
				</div>
			</div>
		</Fragment>
	)
}

export default PageHome
