import { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { Context } from '../context/Context'
import Translator from './Translator'

const Header = () => {
	const {
		switchLanguage,
		french,
		handleMenuVisibility,
		menuVisibility
	} = useContext(Context)

	return (
		<div
			className={`header ${
				menuVisibility ? 'menuIsVisible' : ''
			}`}>
			<button
				className='menu-trigger'
				onClick={() => handleMenuVisibility()}>
				<Translator content='openMenu' />
			</button>

			<div className='menu'>
				<div className='menu-left'>
					<NavLink exact className='logo' to='/'>
						Galerie Espace
					</NavLink>
				</div>

				<div className='menu-right'>
					<NavLink
						onClick={() => handleMenuVisibility()}
						exact
						activeClassName='active'
						to='/'>
						<Translator content='homeLink' />
					</NavLink>
					<NavLink
						onClick={() => handleMenuVisibility()}
						exact
						activeClassName='active'
						to='/programmation'>
						<Translator content='programsLink' />
					</NavLink>
					<button
						className='lang'
						onClick={() => switchLanguage()}>
						{french ? 'English' : 'French'}
					</button>
				</div>
			</div>
		</div>
	)
}

export default Header
