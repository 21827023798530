import { useState, useEffect, useCallback, createContext } from 'react'
import { client } from './client'
import { cleanUpPrograms, cleanUpHomeContent, cleanUpProgramTitleContent, getLang, setLang } from './helpers'
export const Context = createContext()
export const Provider = props => {
	const [isProgramLoading, setIsProgramLoading] = useState(false)
	const [programs, setPrograms] = useState({})
	const [isHomeContentLoading, setIsHomeContentLoading] = useState(false)
	const [homeContent, setHomeContent] = useState({})

	const [programTitleContent, setProgramTitleContent] = useState('')
	const [isProgramTitleContentLoading, setIsProgramTitleContentLoading] = useState(false)

	const [menuVisibility, setMenuVisibility] = useState(false)
	const [french, setFrench] = useState(true)
	const locale = french ? 'fr-CA' : 'en-CA'
	useEffect(() => {
		try {
			const languageFromLocalStorage = getLang('language')
			if (languageFromLocalStorage) {
				if (languageFromLocalStorage === 'fr-CA') {
					setFrench(true)
				} else {
					setFrench(false)
				}
			} else {
				setLang('language', locale)
				setFrench(true)
			}
		} catch (error) {
			console.log(error)
		}
	}, [locale, french])
	const switchLanguage = () => {
		setFrench(!french)
		if (locale === 'fr-CA') {
			setLang('language', 'en-CA')
		} else {
			setLang('language', 'fr-CA')
		}
	}
	const handleMenuVisibility = () => {
		setMenuVisibility(!menuVisibility)
	}

	// PROGRAMS PAGE RELATED CODE
	const savePrograms = useCallback(rawData => {
		const cleanPrograms = cleanUpPrograms(rawData)
		setPrograms(cleanPrograms)
	}, [])
	const getPrograms = useCallback(async () => {
		setIsProgramLoading(true)
		try {
			const response = await client.getEntries({
				content_type: 'programmation',
				locale
			})
			savePrograms(response)
			setIsProgramLoading(false)
		} catch (error) {
			console.log(error)
			setIsProgramLoading(false)
		}
	}, [savePrograms, locale])
	useEffect(() => {
		getPrograms()
	}, [getPrograms])

	// HOME PAGE RELATED CODE
	const saveHomeContent = useCallback(rawData => {
		const cleanHomeContent = cleanUpHomeContent(rawData)
		setHomeContent(cleanHomeContent)
	}, [])
	const getHomeContent = useCallback(async () => {
		setIsHomeContentLoading(true)
		try {
			const response = await client.getEntry('3UTga9IrlghauiVrasoM2T', { locale })
			saveHomeContent(response)
			setIsHomeContentLoading(false)
		} catch (error) {
			console.log(error)
			setIsHomeContentLoading(false)
		}
	}, [saveHomeContent, locale])
	useEffect(() => {
		getHomeContent()
	}, [getHomeContent])

	// PROGRAM TITLE RELATED CODE
	const saveProgramTitleContent = useCallback(rawData => {
		const cleanProgramTitleContent = cleanUpProgramTitleContent(rawData)
		setProgramTitleContent(cleanProgramTitleContent)
	}, [])
	const getProgramTitleContent = useCallback(async () => {
		setIsProgramTitleContentLoading(true)
		try {
			const response = await client.getEntry('4F4HyN3H2HTzTvcEjZMfCT', { locale })
			saveProgramTitleContent(response)
			setIsProgramTitleContentLoading(false)
		} catch (error) {
			console.log(error)
			setIsProgramTitleContentLoading(false)
		}
	}, [saveProgramTitleContent, locale])
	useEffect(() => {
		getProgramTitleContent()
	}, [getProgramTitleContent])

	// GLOBAL STATE AVAILABLE TO ALL COMPONENTS
	const globalState = {
		isProgramLoading,
		programs,
		isHomeContentLoading,
		homeContent,
		programTitleContent,
		isProgramTitleContentLoading,
		french,
		switchLanguage,
		menuVisibility,
		handleMenuVisibility
	}
	return <Context.Provider value={globalState}>{props.children}</Context.Provider>
}
