import React, {
	Fragment,
	useEffect,
	useState,
	useContext
} from 'react'
import { Context } from '../context/Context'
import { Helmet } from 'react-helmet-async'
import Loader from '../components/Loader'
import { staticTranslations } from '../context/staticTranslations'
import Translator from '../components/Translator'

const PageNotFound = () => {
	const [showLoading, setShowLoading] = useState(true)
	const { french } = useContext(Context)
	const { seo404Title, seo404Description } = staticTranslations

	useEffect(() => {
		const countLoad = setTimeout(() => setShowLoading(true), 1000)

		setShowLoading(false)
		return clearTimeout(countLoad)
	}, [setShowLoading])

	if (showLoading) {
		return <Loader />
	}

	return (
		<Fragment>
			<Helmet>
				<title>
					{french
						? seo404Title.french
						: seo404Title.english}
				</title>
				<meta
					name='description'
					content={
						french
							? seo404Description.french
							: seo404Description.english
					}
				/>
			</Helmet>
			<div className='main notfound'>
				<h1 className='lineright marginBottom40'>
					<Translator content='notFoundPageTitle' />
				</h1>
				<div className='table'>
					<p>
						<Translator content='notFoundPageText' />
					</p>
				</div>
			</div>
		</Fragment>
	)
}

export default PageNotFound
