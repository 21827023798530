import React from 'react'

const Loader = () => {
	return (
		<div className='main'>
			<div className='loader'>
				<h1>Loading...</h1>
				<span className='loader-symbol'></span>
			</div>
		</div>
	)
}

export default Loader
