const getDate = rawDate => {
	const d = new Date(rawDate)
	const offset = d.getTimezoneOffset()
	const correctedDate = new Date(d.getTime() + Math.abs(offset * 60000))
	const unixTimeDate = parseInt((correctedDate.getTime() / 1000).toFixed(0))
	const monthIndex = correctedDate.getMonth()
	return { unixTimeDate, monthIndex }
}

export const cleanUpPrograms = rawData => {
	const { items } = rawData
	const cleanItems = items.map(item => {
		const { id } = item.sys
		let outputDate = getDate(item.fields.startDate)
		let { unixTimeDate: timeStamp, monthIndex } = outputDate
		const { startDate, enddate, artiste, medium } = item.fields
		return {
			id,
			startDate,
			enddate,
			artiste,
			medium,
			timeStamp,
			monthIndex
		}
	})

	const sortedCleanItems = cleanItems.sort((a, b) => {
		return a.timeStamp - b.timeStamp
	})

	const programGroup = sortedCleanItems.reduce((programGroup, item) => {
		const date = item.monthIndex
		if (!programGroup[date]) {
			programGroup[date] = []
		}
		programGroup[date].push(item)
		return programGroup
	}, {})

	return programGroup
}

export const cleanUpProgramTitleContent = rawData => {
	const { fields } = rawData
	const { title } = fields
	const cleanProgramTitleData = {
		title
	}

	return cleanProgramTitleData
}

export const cleanUpHomeContent = rawData => {
	const { id } = rawData.sys
	const { fields } = rawData
	const { adresse, conditions, prixSemaine, email, phone } = fields
	const cleanHomeData = {
		id,
		adresse,
		conditions,
		prixSemaine,
		email,
		phone
	}

	return cleanHomeData
}

export const formatMonth = date => {
	const month = date.substring(5, 7)
	const monthNumber = parseInt(month, 10)
	return monthNumber - 1
}

export const formatDay = date => {
	const day = date.substring(8, 10)
	if (day.charAt(0) === '0') {
		return date.substring(9, 10)
	} else {
		return day
	}
}

export const setLang = (lang, value) => {
	localStorage.setItem(lang, value)
}

export const getLang = lang => {
	return localStorage.getItem(lang)
}
