import React, { useContext } from 'react'
import { formatMonth, formatDay } from '../context/helpers'
import { Context } from '../context/Context'
import { staticTranslations } from '../context/staticTranslations'

const Program = props => {
	const { artiste, medium, startDate, enddate } = props
	const { french } = useContext(Context)
	const { monthNames } = staticTranslations

	const unicMonthName = formatMonth(startDate) !== formatMonth(enddate)

	const sepArtistes = artiste.split('/')

	const newSepArtiste = sepArtistes.map((sepArtiste, index) => {
		return (
			<React.Fragment key={index}>
				{index === 0 ? null : <span className='sep'>&bull;</span>}
				{sepArtiste}
			</React.Fragment>
		)
	})

	return (
		<p className='row'>
			<span className='date'>
				{formatDay(startDate)}{' '}
				{unicMonthName && french ? monthNames.french[formatMonth(startDate)] : null}
				{unicMonthName && !french ? monthNames.english[formatMonth(startDate)] : null} →{' '}
				{formatDay(enddate)}{' '}
				{french ? monthNames.french[formatMonth(enddate)] : monthNames.english[formatMonth(enddate)]}
			</span>
			<span className='artist'>
				<strong>{newSepArtiste}</strong> {medium && <em>{medium}</em>}
			</span>
		</p>
	)
}

export default Program
