import React, { useContext, Fragment } from 'react'
import { Context } from '../context/Context'
import { Helmet } from 'react-helmet-async'
import Loader from '../components/Loader'
import { staticTranslations } from '../context/staticTranslations'
import Programs from '../components/Programs'

const PagePrograms = () => {
	const { programs, isProgramLoading, french } = useContext(Context)
	const {
		seoProgramTitle,
		seoProgramDescription
	} = staticTranslations

	if (isProgramLoading) {
		return <Loader />
	}

	return (
		<Fragment>
			<Helmet>
				<title>
					{french
						? seoProgramTitle.french
						: seoProgramTitle.english}
				</title>
				<meta
					name='description'
					content={
						french
							? seoProgramDescription.french
							: seoProgramDescription.english
					}
				/>
			</Helmet>
			<Programs
				programs={programs}
				isProgramLoading={isProgramLoading}
			/>
		</Fragment>
	)
}

export default PagePrograms
