import React, { useContext } from 'react'
import Translator from './Translator'
import { Context } from '../context/Context'
import Obfuscate from 'react-obfuscate'

const Footer = () => {
	const { homeContent } = useContext(Context)
	return (
		<div className='footer'>
			<div className='max-width'>
				<div className='col col-1'>
					<div className='group'>
						<h4>
							La Galerie ESPACE
							<br />
							<Translator content='footerAddressTitle' />
						</h4>
						<p>
							<Translator content='footerAddress' />
							<br />
							<Obfuscate tel={homeContent.phone} />
							<br />
							<Obfuscate email={homeContent.email} />
						</p>
					</div>
				</div>
				<div className='col col-3'>
					<div className='group'>
						<h4>
							<Translator content='footerHoursTitle' />
						</h4>
						<p>
							<Translator content='footerHours' />
							<br />
							<small className='footerNote'>
								<Translator content='footerNote' />
							</small>
						</p>
					</div>
					<div className='credits'>
						<a
							href='http://computatrice.com'
							target='_blank'
							rel='noreferrer'>
							&lt;
							<Translator content='footerDoneBy' />{' '}
							computatrice&gt;
						</a>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Footer
