import React, { useContext, Fragment } from 'react'
import Program from './Program'
import { Context } from '../context/Context'
import { staticTranslations } from '../context/staticTranslations'

const Programs = props => {
	const { programs } = props
	const { french, programTitleContent } = useContext(Context)

	const { monthNames } = staticTranslations

	return (
		<div className='main program'>
			<h1 className='lineright marginBottom40'>
				{programTitleContent && programTitleContent.title ? programTitleContent.title : ''}
			</h1>
			<div className='table'>
				{Object.keys(programs).map(date => {
					return (
						<Fragment key={date}>
							<h3 className='program-month'>
								{french ? monthNames.french[date] : monthNames.english[date]}
							</h3>
							{programs[date].map(program => {
								const { id, startDate, enddate, artiste, medium } = program

								return (
									<Program
										id={id}
										startDate={startDate}
										enddate={enddate}
										artiste={artiste}
										medium={medium}
										key={id}
									/>
								)
							})}
						</Fragment>
					)
				})}
			</div>
		</div>
	)
}

export default Programs
