export const staticTranslations = {
	monthNames: {
		french: [
			'janvier',
			'février',
			'mars',
			'avril',
			'mai',
			'juin',
			'juillet',
			'août',
			'septembre',
			'octobre',
			'novembre',
			'décembre'
		],
		english: [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December'
		]
	},
	programsTitle: {
		french: 'Programmation 2021',
		english: 'Program 2021'
	},
	openMenu: {
		french: 'Voir le menu',
		english: 'Open menu'
	},
	homeLink: {
		french: 'Accueil',
		english: 'Home'
	},
	programsLink: {
		french: 'Programmation',
		english: 'Program'
	},
	seeGoogleMap: {
		french: 'Consulter Google map',
		english: 'See Google map'
	},
	homePageTitle: {
		french: 'Les conditions de location',
		english: 'Galerie Espace: Rental conditions'
	},
	notFoundPageTitle: {
		french: '404 Introuvable',
		english: '404 Not found'
	},
	notFoundPageText: {
		french:
			'Désolé, nous ne trouvons pas la page que vous recherchez.',
		english: "Sorry, we can't find the page you're looking for."
	},
	footerAddressTitle: {
		french: 'Centre de diffusion en art actuel',
		english: 'Diffusion center in contemporary art'
	},
	footerAddress: {
		french: '4844, Boul. Saint-Laurent, Montréal, Québec H2T 1R5',
		english: '4844, Boul. Saint-Laurent, Montreal, Quebec H2T 1R5'
	},
	footerHoursTitle: {
		french: "Heures d'ouverture",
		english: 'Opening hours'
	},
	footerHours: {
		french: 'Tous les jours - 13h à 18h',
		english: 'Everyday - 1PM to 6PM'
	},
	footerNote: {
		french: 'Changement d’horaire sans préavis',
		english: 'Hours may change without notice'
	},
	footerDoneBy: {
		french: 'fait par',
		english: 'done by'
	},
	seoHomeTitle: {
		french: 'Bienvenue à La Galerie Espace',
		english: 'Welcome to Galerie Espace'
	},
	seoHomeDescription: {
		french:
			'La Galerie ESPACE est un organisme indépendant voué à la promotion et à la diffusion en arts libre. Comme nous ne recevons aucune subvention des organismes gouvernementaux, les dépenses administratives qui sont reliées aux expositions seront sous forme de location.',
		english:
			'Galerie ESPACE is an independent organization dedicated to the promotion and dissemination of free arts. As we do not receive any subsidy from government agencies, administrative expenses related to exhibitions will be in the form of rental.'
	},
	seoProgramTitle: {
		french: 'Programmation 2021 de la Galerie ESPACE',
		english: 'Program 2021 at Galerie ESPACE'
	},
	seoProgramDescription: {
		french:
			'Programmation 2021 de la Galerie ESPACE. Toutes les expositions',
		english:
			'Program 2021 for Galerie ESPACE. All the exhibitions'
	},
	seo404Title: {
		french: 'Bienvenue à La Galerie Espace',
		english: 'Welcome at Galerie Espace'
	},
	seo404Description: {
		french: 'Désolé, nous ne trouvons pas ce que vous cherchez.',
		english: "Sorry, we can't find what you're looking for."
	}
}
