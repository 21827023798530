import React, { Fragment } from 'react'
import Translator from './Translator'
import LazyLoad from 'react-lazyload'
import placeholder from '../assets/images/placeholder.png'
import map from '../assets/images/map.png'

const HomeMap = () => {
	return (
		<Fragment>
			<div className='map'>
				<LazyLoad once={true} placeholder={placeholder}>
					<img src={map} alt='Galerie Espace' />
				</LazyLoad>
			</div>
			<a
				href='https://www.google.com/maps/place/4844+St+Laurent+Blvd,+Montreal,+QC+H2T+1R5/@45.521836,-73.5902376,17z/data=!4m5!3m4!1s0x4cc91bd45abacc0f:0xcc175e884114404e!8m2!3d45.5217753!4d-73.5903137'
				className='map-button'
				target='_blank'
				rel='noreferrer'>
				<Translator content='seeGoogleMap' />
			</a>
		</Fragment>
	)
}

export default HomeMap
